<template>
  <DialogFormWrapper>
    <template #form>
      <v-form
        id="sendFileForm"
        ref="sendFileForm"
        class="w-100 pt-6"
        @submit.prevent="submitSendFileForm"
      >
        <v-col cols="12">
          <ForwardDocument
            :item="{contactIds, externalContactEmails}"
            @update="updateContacts"
          />
        </v-col>
      </v-form>
    </template>
    <template #submit>
      <v-btn
        color="primary"
        class="base-hover"
        :loading="isProcessing"
        type="submit"
        form="sendFileForm"
      >
        Wyślij plik
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import DialogFormWrapper from './Partials/DialogFormWrapper'
import ForwardDocument from '../Forms/Partials/ForwardDocument'
import rules from '../../utils/validators'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    DialogFormWrapper,
    ForwardDocument,
  },
  data() {
    return {
      rules,
      contactIds: [],
      externalContactEmails: [],
    }
  },
  created() {
    if (this.order.client) {
      this.getClient(this.order.client.id).then(() => {
        this.contactIds = this.clientContactsWithEmail
          .map(contact => contact.invoiceReceivable && contact.id)
          .filter(id => !!id)
      })
    }
  },
  computed: {
    ...mapState({
      isProcessing: state => state.order.isProcessing,
      order: state => state.layout.dialog.item,
      file: state => state.layout.dialog.file,
      clientContacts: state => state.client.entity?.contacts,
    }),

    clientContactsWithEmail() {
      return this.clientContacts?.filter((contact) => !!contact.email) || []
    },
  },
  methods: {
    ...mapActions({
      getClient: 'client/getSingleClient',
      sendFile: 'order/sendFile',
    }),
    updateContacts({ contactIds, externalContactEmails }) {
      this.contactIds = contactIds
      this.externalContactEmails = externalContactEmails
    },
    submitSendFileForm() {
      if (this.$refs.sendFileForm.validate()) {
        this.sendFile({
          contactIds: this.contactIds,
          externalContactEmails: this.externalContactEmails,
          fileId: this.file.id,
        })
      }
    },
  },
}
</script>
